import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import {
  Box,
  CircularProgress,
  Divider,
  Paper,
  makeStyles,
  Typography,
  Button,
} from '@material-ui/core';
import LogsTable from '../components/LogsTable';
import { exportData, fetch as fetchLogs } from '../services/logs';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: theme.paper,
}));
const enterGameAction = 'ENTER_GAME';
const submitScoreAction = '/submit';
const Logs = () => {
  const { paper } = useStyles();
  const [enterGameLogs, setEnterGameLogs] = useState([]);
  const [endGameLogs, setEndGameLogs] = useState([]);
  const [fetching, setFetching] = useState(true);
  const { id } = useParams();
  const convertLogs = (data) => {
    const enterGameLogs = data
      .filter((v) => v.action === enterGameAction)
      .map((v) => ({
        time: v.createdAt.toDate().toLocaleString(),
      }));

    const endGameLogs = data
      .filter((v) => v.action === submitScoreAction)
      .map((v) => ({
        time: v.createdAt.toDate().toLocaleString(),
        score: v.value.gameScore,
      }));
    return { enterGameLogs, endGameLogs };
  };
  useEffect(() => {
    const fetch = async () => {
      setFetching(true);
      const data = await fetchLogs(id);
      const { enterGameLogs, endGameLogs } = convertLogs(data);
      setEnterGameLogs(enterGameLogs);
      setEndGameLogs(endGameLogs);
      setFetching(false);
    };
    fetch();
  }, [id]);

  const handleExport = async () => {
    exportData(id, enterGameLogs, endGameLogs);
  };
  return (
    <Layout>
      <Paper className={paper}>
        <Box p={1}>
          <Typography variant='h4'>Logs</Typography>
          <Typography variant='subtitle1'>{id}</Typography>
          {fetching ? (
            <Box display='flex' justifyContent='center'>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box py={1} mt={1}>
                <Typography variant='h6'>ข้อมูลการเข้าเล่นเกม</Typography>
                <Box my={2}>
                  <LogsTable
                    headCells={{ time: 'เวลา' }}
                    data={enterGameLogs}
                  />
                </Box>
              </Box>
              <Divider />
              <Box py={1} mt={1}>
                <Typography variant='h6'>ข้อมูลการเล่นเกมจบ</Typography>
                <Box my={2}>
                  <LogsTable
                    headCells={{ time: 'เวลา', score: 'คะแนน' }}
                    data={endGameLogs}
                  />
                </Box>
              </Box>
              {(endGameLogs.length > 0 || enterGameLogs.length > 0) && (
                <Box display='flex' justifyContent='flex-end'>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleExport}
                  >
                    Export
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>
      </Paper>
    </Layout>
  );
};

export default Logs;
