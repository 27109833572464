import React, { useState } from 'react';
import {
  FilledInput,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import Layout from '../components/Layout';
import RankingTable from '../components/RankingTable';

import rankingService from '../services/Ranking';

const useStyles = makeStyles((theme) => ({
  paper: theme.paper,
  row: theme.row,
  header: {
    justifyContent: 'space-between',
  },
  formControl: {
    marginLeft: '8px',
  },
  select: {
    minWidth: '200px',
  },
  datePicker: {
    display: 'inline-flex',
    marginLeft: '8px',
    formControl: {
      color: 'yellow',
      filledInput: {
        dateFormatInput: {
          height: '100%',
        },
      },
    },
  },
  datePickerInput: {
    height: 'auto',
  },
}));

const Ranking = () => {
  const [period, setPeriod] = useState('allTime');
  const { paper, row, header, formControl, select } = useStyles();

  const onChangePeriod = (event) => {
    setPeriod(event.target.value);
  };

  const handleExport = async (e) => {
    const { docs } = await rankingService.getRank(period);
    rankingService.exportData(period, docs);
  };
  return (
    <Layout>
      <Paper className={paper}>
        <div className={classNames(row, header)}>
          <Typography variant="h4">Ranking</Typography>
          <div>
            <FormControl className={formControl} variant="filled">
              <InputLabel htmlFor="period-select">Period</InputLabel>
              <Select
                className={select}
                value={period}
                onChange={onChangePeriod}
                input={<FilledInput id="period-select" />}
              >
                <MenuItem value={'allTime'}>All Time</MenuItem>
                <MenuItem value={'month'}>This Month</MenuItem>
                <MenuItem value={'lastMonth'}>Last Month</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div>
          <RankingTable time={period} onExport={handleExport} />
        </div>
      </Paper>
    </Layout>
  );
};

export default Ranking;
