import { database } from 'common/firebase';
import XLSX from 'xlsx';
export const fetch = async () => {
  const snapshot = await database.collection('users').get();
  return snapshot.docs.map((doc) => {
    const data = doc.data();
    return { uid: data.uid, identifier: data.email, referrer: data.referrer };
  });
};
export const exportData = (data) => {
  data = [{ uid: 'ไอดี',referrer: 'พนักงานผู้เชิญชวน' ,identifier: 'อีเมล' }, ...data];
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(data, { skipHeader: true });
  XLSX.utils.book_append_sheet(wb, ws, 'Users');
  XLSX.writeFile(wb, `Users.xlsx`);
};
