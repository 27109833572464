import axios from 'axios';
import { auth, api } from 'common/firebase';
import XLSX from 'xlsx';
import moment from 'moment';

class RankingService {
  static getRank = async (time, limit, pageType, uid) => {
    try {
      const token = await auth.currentUser.getIdToken();
      const res = await axios({
        method: 'get',
        url: `${api}/scores/rank`,
        headers: { Authorization: `Bearer ${token}` },
        params: {
          time,
          limit: limit ? limit : null,
          pageType: pageType,
          uid: uid,
        },
      });
      return { docs: res.data.data, hasOtherPage: res.data.hasOtherPage };
    } catch (e) {
      console.log('get score error: ', e);
      throw e;
    }
  };

  static getPeriod = (period) => {
    switch (period) {
      case 'week':
        return `${moment().startOf('week').format('DDMMYYYY')}-${moment()
          .endOf('week')
          .format('DDMMYYYY')}`;
      case 'lastweek':
        return `${moment()
          .add(-1, 'week')
          .startOf('week')
          .format('DDMMYYYY')}-${moment()
          .add(-1, 'week')
          .endOf('week')
          .format('DDMMYYYY')}`;
      case 'month':
        return `${moment().startOf('month').format('DDMMYYYY')}-${moment()
          .endOf('month')
          .format('DDMMYYYY')}`;
      case 'lastmonth':
        return `${moment()
          .add(-1, 'month')
          .startOf('month')
          .format('DDMMYYYY')}-${moment()
          .add(-1, 'month')
          .endOf('month')
          .format('DDMMYYYY')}`;

      default:
        return period;
    }
  };

  static exportData = (period, docs) => {
    // convert raw data to readable
    docs = docs.map((doc) => ({
      id: doc.id,
      name: doc.name,
      score: doc.score,
      repaired: doc.repaired ? 'ภารกิจ' : 'ครบ 90 วัน',
      referrer: doc.referrer,
      updatedAt: moment.unix(doc.updatedAt._seconds).format('DD-MM-YYYY HH:mm'),
    }));
    console.log('docs: ', docs);
    const wb = XLSX.utils.book_new();
    // add header as the first column
    docs = [
      {
        id: 'ไอดี',
        name: 'ชื่อ',
        score: 'คะแนน',
        repaired: 'วิธีจบ',
        referrer: 'พนักงานผู้เชิญชวน',
        updatedAt: 'วันที่',
      },
      ...docs,
    ];
    // skip header in order to use our custom first-row header
    const ws = XLSX.utils.json_to_sheet(docs, { skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, 'report');
    XLSX.writeFile(
      wb,
      `report-ranking-${RankingService.getPeriod(period)}.xlsx`
    );
  };
}

export default RankingService;
