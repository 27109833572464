import { database } from 'common/firebase';
import XLSX from 'xlsx';
export const fetch = async (id) => {
  const snapshot = await database
    .collection('logs')
    .where('id', '==', id)
    .orderBy('createdAt', 'desc')
    .get();

  return snapshot.docs.map((doc) => {
    const data = doc.data();
    return data;
  });
};

export const exportData = (id, enterGame, endGame) => {
  enterGame = [{ time: 'เวลา' }, ...enterGame];
  const wb = XLSX.utils.book_new();
  const wsEnterGame = XLSX.utils.json_to_sheet(enterGame, { skipHeader: true });
  XLSX.utils.book_append_sheet(wb, wsEnterGame, 'Enter Game');
  endGame = [{ time: 'เวลา', score: 'คะแนน' }, ...endGame];
  const wsEndGame = XLSX.utils.json_to_sheet(endGame, { skipHeader: true });
  XLSX.utils.book_append_sheet(wb, wsEndGame, 'End Game');
  XLSX.writeFile(wb, `logs-${id}.xlsx`);
};
