import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
const ObjectTable = ({ headCells, data }) => {
  return (
    <TableContainer component={Paper}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            {Object.values(headCells).map((head) => (
              <TableCell variant='head' align='left' key={`header-${head}`}>
                {head}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((value, index) => (
            <TableRow key={index}>
              {Object.keys(headCells).map((key) => (
                <TableCell variant='body' align='left' key={`${key}-${index}`}>
                  {value[key]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ObjectTable;
