import React, { useState } from 'react';
import {
  Chip,
  IconButton,
  Paper,
  Typography,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Search } from '@material-ui/icons';
import classNames from 'classnames';
import Layout from '../components/Layout';

import EvaluationTable from '../components/EvaluationTable';
import { fetch, exportData } from '../services/evaluation';
const useStyles = makeStyles((theme) => ({
  paper: theme.paper,
  row: theme.row,
  header: {
    justifyContent: 'space-between',
  },
  datePicker: {
    display: 'inline-flex',
    marginLeft: '8px',
    formControl: {
      color: 'yellow',
      filledInput: {
        dateFormatInput: {
          height: '100%',
        },
      },
    },
  },
  datePickerInput: {
    height: 'auto',
  },
}));

const Evaluation = () => {
  const { paper, row, header } = useStyles();
  const [search, setSearch] = useState('');
  const [filterId, setFilterId] = useState();
  const handleExport = async (_) => {
    const { docs } = await fetch(undefined, undefined, undefined, filterId);
    exportData(docs, filterId);
  };
  const handleExportLatest = async () => {
    const { docs } = await fetch();
    let uniqueArray = [];
    docs.forEach((value) => {
      if (!uniqueArray.some((item) => item.id === value.id)) {
        uniqueArray = [...uniqueArray, value];
      }
    });
    exportData(uniqueArray);
  };
  const handleTextField = (event) => {
    setSearch(event.target.value);
  };
  const handleSearch = () => {
    setFilterId(search);
  };
  return (
    <Layout>
      <Paper className={paper}>
        <div className={classNames(row, header)}>
          <Typography variant='h4'>Evaluation</Typography>
          {filterId ? (
            <Chip
              label={filterId}
              color='primary'
              onDelete={() => {
                setSearch('');
                setFilterId();
              }}
            />
          ) : (
            <TextField
              placeholder='Search ID'
              variant='outlined'
              size='small'
              inputProps={{ 'aria-label': 'search' }}
              InputProps={{
                endAdornment: (
                  <IconButton aria-label='button-search' onClick={handleSearch}>
                    <Search />
                  </IconButton>
                ),
              }}
              value={search}
              onChange={handleTextField}
            />
          )}
        </div>
        <div>
          <EvaluationTable
            filterId={filterId}
            onExport={handleExport}
            onExportLatest={handleExportLatest}
          />
        </div>
      </Paper>
    </Layout>
  );
};

export default Evaluation;
