import axios from 'axios';
import { auth, api } from 'common/firebase';
import XLSX from 'xlsx';
export const fetch = async (limit, refId, action, filterId) => {
  try {
    const token = await auth.currentUser.getIdToken();
    const res = await axios({
      method: 'get',
      url: `${api}/evaluations/`,
      headers: { Authorization: `Bearer ${token}` },
      params: {
        limit: limit ? limit : null,
        refId,
        action,
        filterId,
      },
    });
    return {
      docs: res.data.response.data.map((v) => ({
        ...v,
        createdAt: v.createdAt,
      })),
      hasOtherPage: res.data.response.hasOtherPage,
    };
  } catch (e) {
    console.log('get evaluations error: ', e);
    throw e;
  }
};
export const exportData = (docs, filterId) => {
  docs = docs.map((doc) => {
    const section1Data = Object.assign(
      {},
      ...doc.section1.values.map((v, i) => ({ [`Section 1 No.${i + 1}`]: v }))
    );
    const section2Data = Object.assign(
      {},
      ...doc.section2.values.map((v, i) => ({ [`Section 2 No.${i + 1}`]: v }))
    );
    return {
      ID: doc.id,
      ...section1Data,
      'Comments 1': doc.section1.comments,
      ...section2Data,
      'Comments 2': doc.section2.comments,
    };
  });

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(docs, { skipHeader: false });
  XLSX.utils.book_append_sheet(wb, ws, 'report');
  XLSX.writeFile(
    wb,
    `report-evaluations${filterId ? `-${filterId}` : ''}.xlsx`
  );
};
