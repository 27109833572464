import React, { useEffect, useState, useContext, useCallback } from 'react';
import {
  Button,
  CircularProgress,
  Checkbox,
  Divider,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import AuthContext from '../context/auth';
import { fetch } from '../services/evaluation';

const useStyles = makeStyles({
  controlPanel: {
    display: 'flex',
    padding: '16px 8px',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  controlPanelItem: {
    marginRight: '4px',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '64px',
  },
  tableCell: {
    maxWidth: '200px',
    verticalAlign: 'baseline',
  },
});
const limit = 10;
const EvaluationTable = ({ onExport, filterId, onExportLatest }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(limit);
  const [hasOtherPage, setHasOtherPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const { state } = useContext(AuthContext);
  const { controlPanel, controlPanelItem, progress, tableCell } = useStyles();
  const getEvaluation = useCallback(
    (pageType, uid) => {
      return fetch(limit, uid, pageType, filterId);
    },
    [filterId]
  );

  useEffect(() => {
    if (!state.loading) {
      setItemPerPage(limit);
      getEvaluation().then(({ docs, hasOtherPage }) => {
        setData(docs);
        setHasOtherPage(hasOtherPage);
        setLoading(false);
      });
    }
  }, [getEvaluation, state.loading]);
  useEffect(() => {
    if (!state.loading) {
      setLoading(true);
      setData([]);
      setPage(1);
      getEvaluation().then(({ docs, hasOtherPage }) => {
        setData(docs);
        setHasOtherPage(hasOtherPage);
        setLoading(false);
      });
    }
  }, [getEvaluation, state.loading]);
  const prevPage = () => {
    setPage(page - 1);
    setLoading(true);
    setData([]);
    getEvaluation('prev', data[0].uid).then(({ docs, _ }) => {
      setData(docs);
      setHasOtherPage(true);
      setLoading(false);
    });
  };
  const nextPage = () => {
    setPage(page + 1);
    setLoading(true);
    setData([]);
    getEvaluation('next', data[data.length - 1].uid).then(
      ({ docs, hasOtherPage }) => {
        setData(docs);
        setHasOtherPage(hasOtherPage);
        setLoading(false);
      }
    );
  };
  return (
    <>
      {loading ? (
        <div className={progress}>
          <CircularProgress />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='left'>ID</TableCell>
                {['Section 1', 'Comments', 'Section 2', 'Comments'].map(
                  (value, index) => (
                    <TableCell
                      className={tableCell}
                      align='center'
                      key={`${value}_${index}`}
                    >
                      {value}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(({ id, section1, section2 }, index) => (
                <TableRow key={`${id}-${index}`}>
                  <TableCell className={tableCell} align='left'>
                    {id}
                  </TableCell>
                  <TableCell className={tableCell} align='center'>
                    {section1.values.map((v) => v + ' ')}
                  </TableCell>
                  <TableCell className={tableCell} align='left'>
                    {section1.comments}
                  </TableCell>
                  <TableCell className={tableCell} align='center'>
                    {section2.values.map((v, i) => (
                      <Checkbox key={`value-p2-${i}`} disabled checked={v} />
                    ))}
                  </TableCell>
                  <TableCell className={tableCell} align='left'>
                    {section2.comments}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Divider />
      <div className={controlPanel}>
        <Button
          variant='outlined'
          color='primary'
          style={{ marginRight: '10px' }}
          onClick={onExportLatest}
        >
          Export Latest
        </Button>
        {data.length > 0 && (
          <>
            <Button
              variant='contained'
              color='primary'
              style={{ marginRight: '10px' }}
              onClick={onExport}
            >
              Export
            </Button>
          </>
        )}
        {data.length === 0 ? null : (
          <Typography className={controlPanelItem}>
            {1 + (page - 1) * itemPerPage} -{' '}
            {itemPerPage * (page - 1) + data.length} of many
          </Typography>
        )}
        <IconButton
          className={controlPanelItem}
          disabled={page === 1 || loading}
          onClick={prevPage}
        >
          <NavigateBefore />
        </IconButton>
        <IconButton
          className={controlPanelItem}
          disabled={data.length < itemPerPage || loading || !hasOtherPage}
          onClick={nextPage}
        >
          <NavigateNext />
        </IconButton>
      </div>
    </>
  );
};

export default EvaluationTable;
