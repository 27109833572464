import React from 'react';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Info } from '@material-ui/icons';
const useStyles = makeStyles({
  tableNumberCell: {
    width: '50px',
  },
});
const UserList = ({ users, offset, onClickInfo }) => {
  const { tableNumberCell } = useStyles();
  return (
    <>
      <Box my={1}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell variant="head">No.</TableCell>
                <TableCell variant="head">UID</TableCell>
                <TableCell variant="head">Referrer</TableCell>
                <TableCell variant="head" align="right">
                  Identifier
                </TableCell>
                <TableCell variant="head" align="right">
                  Logs
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, i) => (
                <TableRow key={i}>
                  <TableCell className={tableNumberCell} align="center">
                    {offset + i + 1}
                  </TableCell>
                  <Box component={TableCell} width="150px">
                    {user.uid}
                  </Box>
                  <Box component={TableCell} width="150px">
                    {user.referrer}
                  </Box>
                  <TableCell align="right">{user.identifier}</TableCell>
                  <Box component={TableCell} width="50px" height="60px">
                    {user.identifier && (
                      <IconButton
                        size="small"
                        onClick={() => {
                          onClickInfo(user.uid);
                        }}
                      >
                        <Info fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default UserList;
