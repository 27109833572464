import React, { useEffect, useState, useContext, useCallback } from 'react';
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import AuthContext from '../context/auth';
import rankingService from '../services/Ranking';

const useStyles = makeStyles({
  controlPanel: {
    display: 'flex',
    padding: '16px 8px',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  controlPanelItem: {
    marginRight: '4px',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '64px',
  },
  secondaryText: {
    paddingLeft: '30px',
    display: 'inline',
  },
});
const limit = 10;
const RankingTable = ({ time, onExport }) => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(limit);
  const [hasOtherPage, setHasOtherPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const { state } = useContext(AuthContext);
  const { controlPanel, controlPanelItem, progress, secondaryText } =
    useStyles();
  const getRank = useCallback(
    (pageType, uid) => {
      console.log(`getRank time:${time} uid: ${uid} `);
      return rankingService.getRank(time, limit, pageType, uid);
    },
    [time]
  );

  useEffect(() => {
    if (!state.loading) {
      setItemPerPage(limit);
      getRank().then(({ docs, hasOtherPage }) => {
        setItems(docs);
        setHasOtherPage(hasOtherPage);
        setLoading(false);
      });
    }
  }, [getRank, state.loading]);
  useEffect(() => {
    if (!state.loading) {
      console.log('update rankType');
      setLoading(true);
      setItems([]);
      setPage(1);
      getRank().then(({ docs, hasOtherPage }) => {
        setItems(docs);
        setHasOtherPage(hasOtherPage);
        setLoading(false);
      });
    }
  }, [time, getRank, state.loading]);
  const prevPage = () => {
    setPage(page - 1);
    setLoading(true);
    setItems([]);
    getRank('prev', items[0].id).then(({ docs, hasOtherPage }) => {
      setItems(docs);
      setHasOtherPage(true);
      setLoading(false);
    });
  };
  const nextPage = () => {
    setPage(page + 1);
    setLoading(true);
    setItems([]);
    getRank('next', items[items.length - 1].id).then(
      ({ docs, hasOtherPage }) => {
        setItems(docs);
        setHasOtherPage(hasOtherPage);
        setLoading(false);
      }
    );
  };
  return (
    <>
      <List>
        {loading ? (
          <div className={progress}>
            <CircularProgress />
          </div>
        ) : null}
        {items.map((item, index) => {
          return (
            <ListItem key={index}>
              <ListItemText
                primary={`${(page - 1) * itemPerPage + (index + 1)}. ${
                  item.name
                }`}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      className={secondaryText}
                    >{`score: ${item.score.toLocaleString()}`}</Typography>
                    <Typography
                      component="span"
                      className={secondaryText}
                    >{`วิธีจบ: ${
                      item.repaired ? 'ภารกิจ' : 'ครบ 90 วัน'
                    }`}</Typography>
                    {item.referrer ? (
                      <Typography
                        component="span"
                        className={secondaryText}
                      >{`พนักงานผู้เชิญชวน: ${item.referrer}`}</Typography>
                    ) : null}
                  </React.Fragment>
                }
              />
            </ListItem>
          );
        })}
      </List>
      <Divider />
      <div className={controlPanel}>
        {items.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: '10px' }}
            onClick={onExport}
          >
            Export
          </Button>
        )}
        {items.length === 0 ? null : (
          <Typography className={controlPanelItem}>
            {1 + (page - 1) * itemPerPage} -{' '}
            {itemPerPage * (page - 1) + items.length} of many
          </Typography>
        )}
        <IconButton
          className={controlPanelItem}
          disabled={page === 1 || loading}
          onClick={prevPage}
        >
          <NavigateBefore />
        </IconButton>
        <IconButton
          className={controlPanelItem}
          disabled={items.length < itemPerPage || loading || !hasOtherPage}
          onClick={nextPage}
        >
          <NavigateNext />
        </IconButton>
      </div>
    </>
  );
};

export default RankingTable;
