import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createMuiTheme, CssBaseline } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { ThemeProvider } from '@material-ui/styles';
import { AuthProvider } from './context/auth';
import App from './App';
import * as serviceWorker from './serviceWorker';

const muiTheme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: green
  }
});

const theme = {
  ...muiTheme,
  paper: {
    margin: `${muiTheme.spacing(2)}px ${muiTheme.spacing(4)}px`,
    padding: muiTheme.spacing(1)
  },
  row: {
    display: 'flex',
    padding: '8px'
  }
};

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <CssBaseline />
        <App />
      </AuthProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
