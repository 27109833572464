import React, { useContext } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import AuthContext from './context/auth';
import Login from './screens/Login';
import Ranking from './screens/Ranking';
import Users from './screens/Users';
import Evaluation from './screens/Evaluation';
import Logs from './screens/Logs';

const App = ({ location }) => {
  const { state } = useContext(AuthContext);
  if (state.loading) return <div>Loading ...</div>;
  if (state.isAdmin && location.pathname === '/login')
    return <Redirect to='/' />;
  if (!state.isAdmin && location.pathname !== '/login')
    return <Redirect to='/login' />;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Switch>
        <Route path='/' render={() => <Redirect to='users' />} exact />
        <Route path='/login' component={Login} exact />
        <Route path='/ranking' component={Ranking} exact />
        <Route path='/users' component={Users} exact />
        <Route path='/evaluation' component={Evaluation} />
        <Route path='/logs/:id' component={Logs} />
      </Switch>
    </MuiPickersUtilsProvider>
  );
};

export default withRouter(App);
