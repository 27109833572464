import { Box, TablePagination, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import ObjectTable from './ObjectTable';

const LogsTable = ({ headCells, data }) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startAt = page * rowsPerPage;
  const slicedData = data.slice(startAt, startAt + rowsPerPage);
  return (
    <Box
      display='flex'
      flexDirection='column'
      minHeight='419px'
      justifyContent='space-between'
    >
      {data.length === 0 ? (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='150px'
        >
          <Typography color='textSecondary'>ไม่พบข้อมูล</Typography>
        </Box>
      ) : (
        <>
          <ObjectTable headCells={headCells} data={slicedData} />
          <Box justifySelf='flex-end'>
            <TablePagination
              component='div'
              count={data.length}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default LogsTable;
