import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Box,
  CircularProgress,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  IconButton,
  Paper,
  TablePagination,
  TextField,
  Typography,
  Switch,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Layout from '../components/Layout';
import Upload from '../components/Upload';
import UserList from '../components/UserList';
import { api, storage } from 'common/firebase';
import { exportData, fetch } from '../services/users';
import { useHistory } from 'react-router-dom';

import { Search } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  paper: theme.paper,
}));

const Users = () => {
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackBar] = useState(false);
  const [message, setMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [targetFile, setTargetFile] = useState(null);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [identifiedOnly, setIdentifiedOnly] = useState(false);
  const { paper } = useStyles();
  const [fetching, setFetching] = useState(false);
  const [filterId, setFilterId] = useState();
  const [search, setSearch] = useState('');
  const history = useHistory();

  const fetchUsers = async () => {
    setFetching(true);
    const users = await fetch();
    setUsers(users);
    setFetching(false);
  };
  useEffect(() => {
    fetchUsers();
  }, []);
  const handleOpenConfirm = (file) => {
    setTargetFile(file);
    setOpenDialog(true);
  };
  const handleDownloadTemplate = async () => {
    window.open(
      await storage.ref('import-user-template.xlsx').getDownloadURL()
    );
  };
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleIdentifiedOnlyChange = (event) => {
    setIdentifiedOnly(event.target.checked);
    setPage(0);
  };
  const handleUpload = async (file) => {
    setOpenDialog(false);
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    try {
      await axios({
        method: 'post',
        url: `${api}/upload/users`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
      setMessage(`นำเข้าสำเร็จ`);
      fetchUsers();
    } catch (e) {
      console.error(e);
      setMessage('เกิดข้อผิดพลาด');
    } finally {
      setLoading(false);
      setSnackBar(true);
    }
  };
  const handleClickInfo = (id) => {
    history.push(`/logs/${id}`);
  };
  const startAt = page * rowsPerPage;
  const filteredUsers = users.filter(
    (user) =>
      (!filterId || user.uid.includes(filterId)) &&
      (!identifiedOnly || user.identifier)
  );
  const slicedUsers = filteredUsers.slice(startAt, startAt + rowsPerPage);
  const handleExport = () => {
    exportData(filteredUsers);
  };

  const handleTextField = (event) => {
    setSearch(event.target.value);
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setFilterId(search);
    setIdentifiedOnly(false);
    setPage(0);
  };
  return (
    <Layout>
      <Paper className={paper}>
        <Box p={1}>
          <Typography variant='h4'>Users</Typography>
          {fetching ? (
            <Box display='flex' justifyContent='center'>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box display='flex' justifyContent='flex-end'>
                <FormControlLabel
                  control={<Switch color='primary' />}
                  label='Identified Only'
                  checked={identifiedOnly}
                  onChange={handleIdentifiedOnlyChange}
                />
                {filterId ? (
                  <Chip
                    label={filterId}
                    color='primary'
                    onDelete={() => {
                      setSearch('');
                      setFilterId();
                    }}
                  />
                ) : (
                  <>
                    <form onSubmit={handleSearch}>
                      <TextField
                        placeholder='Search ID'
                        variant='outlined'
                        size='small'
                        inputProps={{ 'aria-label': 'search' }}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              aria-label='button-search'
                              type='submit'
                            >
                              <Search />
                            </IconButton>
                          ),
                        }}
                        value={search}
                        onChange={handleTextField}
                      />
                    </form>
                  </>
                )}
              </Box>
              <UserList
                users={slicedUsers}
                offset={startAt}
                onClickInfo={handleClickInfo}
              />
              <TablePagination
                component='div'
                count={filteredUsers.length}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
              <Box display='flex' justifyContent='flex-end'>
                <Button
                  onClick={handleExport}
                  variant='contained'
                  color='primary'
                >
                  Export
                </Button>
              </Box>
            </>
          )}

          <Upload
            topic='Import Users'
            loading={loading}
            snackbar={snackbar}
            setSnackBar={setSnackBar}
            message={message}
            handleUpload={handleOpenConfirm}
            handleDownloadTemplate={handleDownloadTemplate}
          />

          <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>
              {'Confirm upload?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                Do you confirm to import user?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)} color='primary'>
                Cancel
              </Button>
              <Button
                onClick={() => handleUpload(targetFile)}
                color='primary'
                autoFocus
              >
                Upload
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Paper>
    </Layout>
  );
};

export default Users;
